<template>
  <div class="profile-nickname-wrapper df-styles">
    <van-form class="pt30">
      <van-field v-model="nickname" clearable maxlength="20" />
      <p class="text-r">{{ nickname.length }} / 20</p>
    </van-form>
    <div class="profile-nickname-wrapper-notice">
      <div class="profile-nickname-wrapper-notice-item">
        <p class="dot">
          {{ $t('profile.nickChangeText') }}
        </p>
      </div>
      <div class="profile-nickname-wrapper-notice-item">
        <p
        class="dot"
          >
          {{ $t('profile.nickApproveText') }}
        </p>
      </div>
    </div>
    <van-button type="primary" block class="btn-logout" :color="nickname.length ? btnBg : defaultBg" @click="onUpdate">{{ $t('auth.submit') }}</van-button>
  </div>
</template>

<script>
import { userInfosUpdate } from '@/api/profile.js'
export default {
  data () {
    return {
      nickname: '',
      btnBg: 'var(--primary-color)',
      defaultBg: 'var(--main-text-3)'
    }
  },
  created () {
    this.nickname = this.$store.state.userInfo?.nickname
  },
  methods: {
    onUpdate () {
      userInfosUpdate({
        nickname: this.nickname
      }).then(res => {
        if (res.data) {
          this.$toast(this.$t('nav.successText'))
          this.$nextTick(() => {
            this.$router.push('/profile')
          })
        }
      })
    }
  }
}
</script>
<style lang="less">
.profile-nickname-wrapper {
  padding: 0 30px;
  background-color: var(--background-color-4);
  .pt30 {
    padding-top: 32px;
  }
  .text-r {
    margin-top: 8px;
    padding-right: 2px;
    color: var(--main-text-3);
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 150% */
  }
  .btn-logout {
    margin-top: 64px;
    border-radius: 8px;
  }
  .profile-nickname-wrapper-notice {
    margin-top: 28px;
  }
  .profile-nickname-wrapper-notice-item {
    margin-bottom: 16px;
    color: var(--main-text-3);
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 150% */
    .dot {
      position: relative;
      padding-left: 36px;
      &::after {
        content: '';
        position: absolute;
        left: 10px;
        top: 16px;
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 8px;
        background: var(--primary-color);
      }
    }
  }
}
</style>
